import { Image } from '@emico-react/image'
import cx from 'classnames'
import { LazyProductPage } from 'components/src/chunks'
import { PlaybackEvent } from 'components/src/media/Video/Video'
import Link from 'components/src/navigation/Link'
import { ProductCardFragment } from 'components/src/ProductCardFragment'
import useHover from 'components/src/utils/useHover'
import * as React from 'react'

import styles from './Card.module.scss'

// Determines how long the card is visible after it is made "active".
// A card becomes "active" when its quepoint in the video is reached.
// It becomes "inactive" when the next quepoint is reached, or when
// the video ends
const CARD_VISIBILITY_DURATION = 6000

interface Props extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onClick'> {
    product: ProductCardFragment
    selectedCard: string | undefined
    activeCard: string | undefined
    carouselEnabled: boolean
    isVideoReady: boolean
    progress: PlaybackEvent
    onClick?(
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        product: ProductCardFragment,
    ): void
    onHover?(product: ProductCardFragment, isHovered: boolean): void
    onVisibilityChange(isVisible: boolean): void
}

const HeroVideoCard = ({
    className,
    product,
    activeCard,
    selectedCard,
    onClick,
    isVideoReady,
    progress,
    style,
    onHover,
    carouselEnabled,
    onVisibilityChange,
    ...otherProps
}: Props) => {
    const isActive = activeCard === product.sku
    const isSelected = selectedCard === product.sku
    const [hoverRef, isHovered] = useHover<HTMLDivElement>()
    const [isVisible, setVisible] = React.useState(false)
    const [timer, setTimer] = React.useState<NodeJS.Timeout>()

    /* eslint-disable react-hooks/exhaustive-deps */
    // We disable exhaustive-deps here because the code below
    // should not update when timer is changed.
    // A new timer should only be set when isActive becomes true (again)
    React.useEffect(() => {
        const stopTimer = () => {
            if (!timer) {
                return
            }
            clearTimeout(timer)
            setTimer(undefined)
        }

        if (isActive) {
            setVisible(true)
            onVisibilityChange(true)
            setTimer(
                setTimeout(() => {
                    setVisible(false)
                    onVisibilityChange(false)
                }, CARD_VISIBILITY_DURATION),
            )
        } else {
            stopTimer()
        }
        return stopTimer
    }, [isActive])
    /* eslint-enable react-hooks/exhaustive-deps */

    const handleClick = (
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    ) => {
        if (!isVisible) {
            event.preventDefault()
            event.stopPropagation()
            return false
        }

        if (onClick) {
            onClick(event, product)
        }
        return
    }

    const handleMouseEnter = () => {
        LazyProductPage.preload()
    }

    React.useEffect(() => {
        if (onHover) {
            onHover(product, isHovered)
        }
    }, [isHovered, product, onHover])

    return (
        <Link
            name={product.name}
            category="productCard"
            to={`/${product.urlKey}`}
            title={product.name}
            onClick={handleClick}
            onMouseEnter={handleMouseEnter}
            preload="hover"
            className={cx(
                styles.link,
                {
                    [styles.active]:
                        (isSelected && isVisible) ||
                        (!selectedCard && isActive && isVisible),
                    [styles.hovered]: isHovered,
                },
                className,
            )}
        >
            <article
                {...otherProps}
                ref={hoverRef}
                className={cx(styles.base, {
                    [styles.animate]: isVideoReady,
                })}
            >
                <div className={styles.titleWrapper}>
                    <h1 className={styles.title}>{product.name}</h1>
                </div>

                <div className={styles.imageContainer}>
                    <div className={styles.imageWrapper}>
                        <Image
                            alt={product.smallImage?.label || product.name}
                            url={product.smallImage?.url ?? ''}
                            width={170}
                            className={styles.image}
                            lazy={false}
                        />
                    </div>
                </div>
            </article>
        </Link>
    )
}

export default HeroVideoCard
